.gallery-row {
  min-height: 100vh;
  margin: 0 auto;
  padding: 7%;
}

@media(max-width: 1200px) {
  .gallery-row {
    margin: 15% auto;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

/* react-masonry-css */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.gallery-img {
  border-radius: 10px;
}