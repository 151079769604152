.footer {
  min-height: 30vh;
  background: #000000;
  padding: 2%;
}

@media(max-width: 767px) {
  .footer-logo {
    padding: 2% 0;
  }
  .footer-title h1, .footer-title h2, .footer-title h6 {
    font-size: 0.9em;
  }
  .footer-links {
    text-align: center !important;
  }
}

.footer-title, .footer-logo h6 {
  color: var(--orange);
}

.footer-link {
  padding: 0 1rem;
  color: #ffffff;
  border-bottom: 1px dotted var(--orange);
}

.w3code {
  color: #1a1a1a;
  transition: 0.5s ease-in-out;
}

.w3code:hover {
  text-decoration: none;
  border-bottom: 1px dotted #ffffff;
  color: #ffffff;
}