.events-row {
  min-height: 100vh;
  margin: 0 auto;
  padding: 7%;
}

@media(max-width: 1200px) {
  .events-row {
    margin: 15% auto;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

/* react-masonry-css */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
  border-radius: 50px;
}

.events-folder-card {
  background: rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.7);
}

.events-folder-card:hover {
  background: rgba(0,0,0,0.2);
  color: #e79239;
}

.event-img {
  border-radius: 10px;
}

.badge-goldbox {
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #e79239;
  color: #ffffff;
}