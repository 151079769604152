.imprint-row {
  padding: 7% 0;
}

@media(max-width: 1200px) {
  .imprint-row {
    padding: 25% 0;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}