:root {
  --orange: #e79239 !important;
}

.App-main-cont {
  padding: 0;
}

a {
  color: var(--orange);
}