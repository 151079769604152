@media(max-width: 1200px) {
  .dashboard-cont {
    padding: 15% 3%;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

.dashboard-main-col {
  padding: 5% 0;
}

.dashboard-cards-row {
  padding: 2% 3%;
}

@media(max-width: 767px) {
  .dashboard-main-col {
    padding: 10% 0;
  }

  .dashboard-row {
    padding-top: 25% !important;
  }
}

.dashboard-title {
  color: #e79239;
}

.dashboard-addpost-btn a {
  color: #ffffff !important;
}

.dashboard-addpost-btn {
  background: var(--orange) !important;
  border-color: var(--orange) !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
}

.dashboard-addpost-btn:hover {
  box-shadow: none;
}

.dashboard-addpost-btn a:hover {
  text-decoration: none;
  color: #ccc !important;
}

.dashboard-overview {
  color: #ffffff;
}

.table thead th {
  border: none !important;
}

.table-img, table .btn-group {
  transition: 0.3s ease-out;
  border-radius: 5px;
  -webkit-box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
}

table .btn-group:hover {
  box-shadow: none;
}

.table-img:hover {
  transition: 0.3s ease-in;
  transform: scale(2.2);
}

.form-card {
  margin: 3%;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
}

.upload-form-card {
  margin: 3%;
  border: none;
}

button {
  outline: none;
  box-shadow:none !important;
}

.delete-col {
  background: #ffffff;
  padding: 2% 3%;
/*   -webkit-box-shadow: inset 0 .5rem 1rem rgba(0,0,0,.15);
  -moz-box-shadow: inset 0 .5rem 1rem rgba(0,0,0,.15);
  box-shadow: inset 0 .5rem 1rem rgba(0,0,0,.15); */
}

.drop-field {
  padding: 5%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
}

.overlay-text {
  color: white;
  font-size: 30px;
  font-style: italic;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.events-card:hover .overlay, .gallery-card:hover .overlay {
  cursor: not-allowed;
  opacity: 1;
}