.home {
  width: 100%;
}

@media(max-width: 767px) {
  .home-img-row {
    padding-top: 25% !important;
  }
}

.home-title {
  padding-top: 10%;
  color: #e79239;
  font-family: 'Satisfy', cursive;
}

.home-title h1 {
  font-size: 5rem;
}

.slogan {
  font-family: 'Satisfy', cursive;
}

.home-slogan-col {
  padding: 2% 7%;
}

.home-slogan-col img {
  margin-top: -1%;
  padding-left: 5%;
}

.ribbon-row {
  background: #000000;
}

.ribbon-text {
  padding: 5% 0;
  font-family: 'Satisfy', cursive;
  color: #e79239;
  font-size: 3rem;
}

.carousel-row {
  min-height: 50vh;
}

.carousel-left-text {
  padding: 6%;
  font-size: 1.1rem;
}

.carousel-self {
  padding-top: 2%;
}