@media (max-width: 600px) {
  .carousel {
    margin-top: 25%;
  }
}

.carousel {
  margin: 0 auto;
  padding: 5% 10% 15% 10%;
}

@media (min-width: 992px) {
  .carousel {
    transform: scale(1) perspective(1024px) rotateY(-11deg) rotateX(1deg) rotate(0deg);
    overflow: hidden!important;
  }
}

.carousel-inner {
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}