.auth-cont {
  padding: 7% 3%;
}

@media(max-width: 1200px) {
  .auth-cont {
    padding: 25% 3%;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}