.kontakt-cont {
  padding: 7% 3%;
}

@media(max-width: 1200px) {
  .kontakt-cont {
    padding: 25% 3%;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

.kontakt-cont h1 i {
  color: var(--orange);
}

.kontakt-first-ribbon,
.kontakt-second-ribbon {
  padding: 1% 5%;
  line-height: 1.6;
  font-size: 1.1em;
}

.kontakt-form {
  border: 1px solid rgba(160, 62, 111, 0.1);
  border-radius: 10px;
  padding: 5%;
  margin: 3% 0 10%;
  -webkit-box-shadow: 10px 10px 51px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 51px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 51px 0px rgba(0, 0, 0, 0.5);
}

@media(max-width: 1200px) {
  label {
    text-align: center;
  }
  .kontakt-form {
    padding: 5% 7%;
  }
}

.kontakt-form label {
  font-weight: bold;
}

.kontakt-check-label {
  font-weight: normal !important;
}

.kontakt-form .submitBtn {
  background-color: var(--orange) !important;
  border-color: var(--orange) !important;
  -webkit-box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.75);
}

.kontakt-form .submitBtn:hover {
  -webkit-box-shadow: 0px 9px 17px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 17px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 17px -10px rgba(0, 0, 0, 0.75);
}

.form-hint {
  font-size: 0.8rem !important;
}

/* Snackbar */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #017c01b2;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}