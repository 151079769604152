.blog-row {
  min-height: 100vh;
  margin: 0 auto;
  padding: 7%;
}

@media(max-width: 1200px) {
  .blog-row {
    margin: 15% auto;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}
