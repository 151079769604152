body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --orange: #e79239 !important;
}

.App-main-cont {
  padding: 0;
}

a {
  color: #e79239;
  color: var(--orange);
}
/* a {
  color: #ffffff !important;
} */

.custom-navbar {
  background: #000000;
}
.footer {
  min-height: 30vh;
  background: #000000;
  padding: 2%;
}

@media(max-width: 767px) {
  .footer-logo {
    padding: 2% 0;
  }
  .footer-title h1, .footer-title h2, .footer-title h6 {
    font-size: 0.9em;
  }
  .footer-links {
    text-align: center !important;
  }
}

.footer-title, .footer-logo h6 {
  color: var(--orange);
}

.footer-link {
  padding: 0 1rem;
  color: #ffffff;
  border-bottom: 1px dotted var(--orange);
}

.w3code {
  color: #1a1a1a;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.w3code:hover {
  text-decoration: none;
  border-bottom: 1px dotted #ffffff;
  color: #ffffff;
}
.home {
  width: 100%;
}

@media(max-width: 767px) {
  .home-img-row {
    padding-top: 25% !important;
  }
}

.home-title {
  padding-top: 10%;
  color: #e79239;
  font-family: 'Satisfy', cursive;
}

.home-title h1 {
  font-size: 5rem;
}

.slogan {
  font-family: 'Satisfy', cursive;
}

.home-slogan-col {
  padding: 2% 7%;
}

.home-slogan-col img {
  margin-top: -1%;
  padding-left: 5%;
}

.ribbon-row {
  background: #000000;
}

.ribbon-text {
  padding: 5% 0;
  font-family: 'Satisfy', cursive;
  color: #e79239;
  font-size: 3rem;
}

.carousel-row {
  min-height: 50vh;
}

.carousel-left-text {
  padding: 6%;
  font-size: 1.1rem;
}

.carousel-self {
  padding-top: 2%;
}
@media (max-width: 600px) {
  .carousel {
    margin-top: 25%;
  }
}

.carousel {
  margin: 0 auto;
  padding: 5% 10% 15% 10%;
}

@media (min-width: 992px) {
  .carousel {
    -webkit-transform: scale(1) perspective(1024px) rotateY(-11deg) rotateX(1deg) rotate(0deg);
            transform: scale(1) perspective(1024px) rotateY(-11deg) rotateX(1deg) rotate(0deg);
    overflow: hidden!important;
  }
}

.carousel-inner {
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.blog-row {
  min-height: 100vh;
  margin: 0 auto;
  padding: 7%;
}

@media(max-width: 1200px) {
  .blog-row {
    margin: 15% auto;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

.gallery-row {
  min-height: 100vh;
  margin: 0 auto;
  padding: 7%;
}

@media(max-width: 1200px) {
  .gallery-row {
    margin: 15% auto;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

/* react-masonry-css */
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.gallery-img {
  border-radius: 10px;
}
.events-row {
  min-height: 100vh;
  margin: 0 auto;
  padding: 7%;
}

@media(max-width: 1200px) {
  .events-row {
    margin: 15% auto;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

/* react-masonry-css */
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
  border-radius: 50px;
}

.events-folder-card {
  background: rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.7);
}

.events-folder-card:hover {
  background: rgba(0,0,0,0.2);
  color: #e79239;
}

.event-img {
  border-radius: 10px;
}

.badge-goldbox {
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #e79239;
  color: #ffffff;
}
.kontakt-cont {
  padding: 7% 3%;
}

@media(max-width: 1200px) {
  .kontakt-cont {
    padding: 25% 3%;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

.kontakt-cont h1 i {
  color: var(--orange);
}

.kontakt-first-ribbon,
.kontakt-second-ribbon {
  padding: 1% 5%;
  line-height: 1.6;
  font-size: 1.1em;
}

.kontakt-form {
  border: 1px solid rgba(160, 62, 111, 0.1);
  border-radius: 10px;
  padding: 5%;
  margin: 3% 0 10%;
  box-shadow: 10px 10px 51px 0px rgba(0, 0, 0, 0.5);
}

@media(max-width: 1200px) {
  label {
    text-align: center;
  }
  .kontakt-form {
    padding: 5% 7%;
  }
}

.kontakt-form label {
  font-weight: bold;
}

.kontakt-check-label {
  font-weight: normal !important;
}

.kontakt-form .submitBtn {
  background-color: var(--orange) !important;
  border-color: var(--orange) !important;
  box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.75);
}

.kontakt-form .submitBtn:hover {
  box-shadow: 0px 9px 17px -10px rgba(0, 0, 0, 0.75);
}

.form-hint {
  font-size: 0.8rem !important;
}

/* Snackbar */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #017c01b2;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.imprint-row {
  padding: 7% 0;
}

@media(max-width: 1200px) {
  .imprint-row {
    padding: 25% 0;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}
@media(max-width: 1200px) {
  .dashboard-cont {
    padding: 15% 3%;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}

.dashboard-main-col {
  padding: 5% 0;
}

.dashboard-cards-row {
  padding: 2% 3%;
}

@media(max-width: 767px) {
  .dashboard-main-col {
    padding: 10% 0;
  }

  .dashboard-row {
    padding-top: 25% !important;
  }
}

.dashboard-title {
  color: #e79239;
}

.dashboard-addpost-btn a {
  color: #ffffff !important;
}

.dashboard-addpost-btn {
  background: var(--orange) !important;
  border-color: var(--orange) !important;
  color: #ffffff !important;
  box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
}

.dashboard-addpost-btn:hover {
  box-shadow: none;
}

.dashboard-addpost-btn a:hover {
  text-decoration: none;
  color: #ccc !important;
}

.dashboard-overview {
  color: #ffffff;
}

.table thead th {
  border: none !important;
}

.table-img, table .btn-group {
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  border-radius: 5px;
  box-shadow: 0px 9px 17px -1px rgba(0, 0, 0, 0.25);
}

table .btn-group:hover {
  box-shadow: none;
}

.table-img:hover {
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  -webkit-transform: scale(2.2);
          transform: scale(2.2);
}

.form-card {
  margin: 3%;
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
}

.upload-form-card {
  margin: 3%;
  border: none;
}

button {
  outline: none;
  box-shadow:none !important;
}

.delete-col {
  background: #ffffff;
  padding: 2% 3%;
/*   -webkit-box-shadow: inset 0 .5rem 1rem rgba(0,0,0,.15);
  -moz-box-shadow: inset 0 .5rem 1rem rgba(0,0,0,.15);
  box-shadow: inset 0 .5rem 1rem rgba(0,0,0,.15); */
}

.drop-field {
  padding: 5%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
}

.overlay-text {
  color: white;
  font-size: 30px;
  font-style: italic;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.events-card:hover .overlay, .gallery-card:hover .overlay {
  cursor: not-allowed;
  opacity: 1;
}
.auth-cont {
  padding: 7% 3%;
}

@media(max-width: 1200px) {
  .auth-cont {
    padding: 25% 3%;
  }
}

.satisfy {
  font-family: 'Satisfy', cursive;
}
